.count-down-area{
    padding-top: 80px;
}
.countdownwrap {
    text-align: center;
    padding: 40px;
    margin: auto;
    position: relative;
    overflow: hidden;
}
.react-countdown .time-section{
    display: inline-block;
    margin: 0 5px;
    text-align: center;
    position: relative;
    width: 167px;
    padding: 8px;
    height: 135px;
    background-size: cover;
    border: 1px solid rgba(255,255,255, 0.2);
    color: #fff;
}

.react-countdown .time-section:after {
 display: none;
}
.react-countdown .time-text {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 14px!important;
    color: #fff;
}
.react-countdown .time {
    font-size: 60px!important;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0;
    color: #fff;
    font-family: 'Great Vibes', cursive;
}

@media (max-width:767px){
    .react-countdown .time-section {
        margin-bottom: 30px;
    }
}



.count-down-section h2 {
    font-family: "Great Vibes", cursive;
    font-size: 60px;
    color: #fff;
    margin: 0;
}

@media (max-width: 1199px) {
    .count-down-section h2 {
        font-size: 62px;
        font-size: 3.44444rem;
    }
}

@media (max-width: 767px) {
    .count-down-section h2 {
        font-size: 50px;
        font-size: 2.77778rem;
    }
}

.count-down-section h2>span {
    font-size: 30px;
    display: block;
    margin-bottom: 0.6em;
}

.count-down-item2 h2>span {
    font-family: 'Dosis', sans-serif;
    font-size: 24px;
    font-weight: normal;
    display: block;
    margin-bottom: 0.6em;
}

@media (max-width: 1199px) {
    .count-down-section h2>span {
        font-size: 25px;
        font-size: 1.38889rem;
    }
}

@media (max-width: 767px) {
    .count-down-section h2>span {
        font-size: 60px;
        font-size: 1.11111rem;
    }
}

.section-area {
    /*background: url(../../images/3.jpg) no-repeat center center / cover;*/
    padding: 18px 0;
    position: relative;
    z-index: 1;
}
.section-area:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(94, 154, 140, 0.88);
    z-index: -1;
}


.section-area h2 {
    font-size: 45px;
    text-align: center;
    border: 1px solid rgba(255,255,255, 0.2);
    padding: 17.25px 25px;
    display: inline-block;
    margin-left: 23px;
    color:#fff;
}
.clock-area {
    background: #5e9a8d;
    padding: 20px 5px 7px 20px;

}