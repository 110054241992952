.couple-img3{
    overflow: hidden;
    transition: all .3s;
}
.couple-img3 img{
  transform: scale(1);
  transition: all .3s;
}
.couple-wrap2:hover .couple-img3 img{
     transform: scale(1.2);
}