ul.smothscroll {
    position: fixed;
    bottom: 15px;
    right:30px;
    list-style: none;
    z-index: 999;
}
ul.smothscroll a {
    width: 50px;
    height: 50px;
    background:rgba(30,130,103,.6);
    color: #fff;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 47px;
    border:1px solid rgba(30,130,103);
    transition: all .3s;
}
ul.smothscroll a:hover{
    background:rgba(30,130,103);
}