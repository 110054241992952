.gift-area .slick-slide img{
    width: unset;
}
.gift-area{
    padding: 80px 0;
    padding-bottom: 0;
}
.gift-area .section-title p {
    max-width: 640px;
    margin: 0 auto;
}

.gift-item {
    padding: 70px 0;
}



.btn-gift a {
    display: inline-block;
    background: #649e93;
    color: #fff;
    padding: 10px 38px;
    font-size: 18px;
    transition: all .3s;
    font-weight: 600;
    border-radius: 40px;
}


.btn-gift a:hover,
.btn-gift.location-btn:hover {
    background: #fff;
    color: #3f7267;
}